"use strict";
window.CI = window.CI || {};

(function ($$) {
  $$.var = {
    _breakpoint: -1,
    breakpoint: -1,
    windowWidth: -1,
    windowHeight: -1,
    scrollTop: -1,
    scrollBtm: -1,
    tmpScrollTop: -1,
    mql: window.matchMedia("(min-width: 577px) and (max-width: 992px)")
  };
  $$.ua = {
    root: navigator.userAgent,
    isIE: (navigator.userAgent.indexOf('Trident') !== -1) ? true : false,
    isEDGE: (navigator.userAgent.indexOf('Edg') !== -1) ? true : false,
  };
  $$.elem = {
    body: document.querySelector('body'),
    header: document.querySelector('.l-header'),
    container: document.querySelector('.l-container'),
    footer: document.querySelector('.l-footer')
  };
  $$.flag = {
    isPC: (window.matchMedia('(min-width: 993px)').matches),
    isTB: (!window.matchMedia('(max-width: 576px)').matches && !window.matchMedia('(min-width: 993px)').matches),
    isSP: (window.matchMedia('(max-width: 576px)').matches),
  };
  $$.fn = {
    /**
     * メディアクエリに応じたイベントをトリガーする
     */
    mqChecker: () => {
      $$.flag.isPC = $$.flag.isTB = $$.flag.isSP = false;
      if (window.matchMedia('(min-width: 993px)').matches) {
        $$.flag.isPC = true;
        window.dispatchEvent(new Event('isPC'));
      } else if (window.matchMedia('(max-width: 576px)').matches) {
        $$.flag.isSP = true;
        window.dispatchEvent(new Event('isSP'));
      } else {
        $$.flag.isTB = true;
        window.dispatchEvent(new Event('isTB'));
      }
    },
    /**
     * Vanilla JSで要素のスタイル値を返す関数
     * @param {string} _elem 'body' '.class-name' など
     * @param {string} _property 値を取得したいCssのプロパティ名
     */
    getCssValue: (_elem, _property) => {
      let element = _elem;
      let compStyle = getComputedStyle(element, null);
      let result = compStyle.getPropertyValue(_property);
      return result;
    },
    getOffset: (_elem, _type) => {
      let result = 0;
      let rect = _elem.getBoundingClientRect();

      if (_type === 'top') {
        result = rect.top + window.pageYOffset;
      } else if (_type === 'left') {
        result = rect.left + window.pageXOffset;
      } else if (_type === 'right') {
        result = rect.left + window.pageXOffset + _elem.clientWidth;
      } else if (_type === 'bottom') {
        result = rect.top + window.pageYOffset + _elem.clientHeight;
      }
      return result;
    },
    smoothScroll: (target, time = 1000, easing = $$.ease.out.cubic) => {
      let startPos = document.documentElement.scrollTop || document.body.scrollTop;
      let startTime = Date.now();
      let distance = target - startPos;
      let position = 0;

      const runScroll = () => {
        let nowTime = Date.now();
        let elapsed = (time - (nowTime - startTime) > 0) ? (nowTime - startTime) : time;
        let progress = elapsed / time;
        position = startPos + (distance * easing(progress));
        window.scrollTo(0, position);
        if (elapsed < time) requestAnimationFrame(runScroll);
      };
      requestAnimationFrame(runScroll);
    },
    getHiddenElHeight: (_elem) => {
      let clone = _elem.cloneNode(true);
      _elem.parentNode.appendChild(clone);
      clone.style.cssText = "display:block; height:auto; visibility:hidden;";
      let cloneHeight = clone.offsetHeight;
      _elem.parentNode.removeChild(clone);
      return cloneHeight;
    },
  };
  $$.ease = {
    in: {},
    out: {
      cubic: (x) => {
        return 1 - Math.pow(1 - x, 3);
      },
    },
    inout: {}
  };

  const anchorLink = () => {
    let anchorArr = document.querySelectorAll('a');

    [...anchorArr].map((el) => {
      el.addEventListener('click', (e) => {
        // console.log(e);
        let href = el.getAttribute('href');
        if (!href) return;

        let match = href.match(/^\#(.*?)$/);
        if (!match) return;

        let targetID = match[1];
        e.preventDefault();

        let $target = document.getElementById(targetID);
        let targetOffsetTop = $$.fn.getOffset($target, 'top');

        $$.fn.smoothScroll(targetOffsetTop);
      });
    });
  };

  /**
   * グローバル変数に値を代入
   */
  const setGlobalVariables = () => {
    $$.var.windowWidth = document.documentElement.clientWidth;
    $$.var.windowHeight = document.documentElement.clientHeight;
    $$.var.scrollTop = window.pageYOffset;
    $$.var.scrollBtm = window.pageYOffset + $$.var.windowHeight;
  };

  /**
   * DOM Ready時に実行したい処理
   * @param {*} e
   */
  const windowDOMLoadedHandler = () => {
    $$.fn.mqChecker();
    anchorLink();
  };
  /**
   * window Load時に実行したい処理
   */
  const windowLoadHandler = () => {
  };
  /**
   * window Scroll時に実行したい処理
   */
  const windowScrollHandler = () => {
  };
  /**
   * window Resize時に実行したい処理
   */
  const windowResizeHandler = () => {

  };

  ['DOMContentLoaded', 'load', 'resize', 'scroll'].map((event) => {
    window.addEventListener(event, (e) => {
      setGlobalVariables();

      if (event === 'DOMContentLoaded') {
        windowDOMLoadedHandler(e);
      } else if (event === 'load') {
        windowLoadHandler(e);
      } else if (event === 'scroll') {
        windowScrollHandler();
      } else if (event === 'resize') {
        windowResizeHandler();
      }

    });
  });

  window.addEventListener('bodyFixedOn', () => {
    $$.var.tmpScrollTop = $$.var.scrollTop;
    $$.elem.body.style.position = 'fixed';
    $$.elem.body.style.top = `-${$$.var.scrollTop}px`;
  });

  window.addEventListener('bodyFixedOff', () => {
    $$.elem.body.style.position = '';
    $$.elem.body.style.top = ``;
    window.scrollTo(0, $$.var.tmpScrollTop);
  });

  $$.var.mql.addEventListener("change", () => {
    $$.fn.mqChecker();
    window.dispatchEvent(new Event('breakpointSwitched'));
  });

})(window.CI);